var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"AgentEditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.editAgent($event)}}},[(_vm.is_staff)?_c('b-form-group',{attrs:{"label":"Organization","label-for":"Organization"}},[_c('validation-provider',{attrs:{"name":"organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.organizations,"state":errors.length > 0 ? false : null,"disabled":""},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Agent Type","label-for":"Agent Type"}},[_c('validation-provider',{attrs:{"name":"Agent Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.agent_types,"disabled":""},model:{value:(_vm.agent_type),callback:function ($$v) {_vm.agent_type=$$v},expression:"agent_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Unlimited Credit","label-for":"Unlimited"}},[_c('validation-provider',{attrs:{"name":"Unlimited"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.unlimted_options},model:{value:(_vm.unlimited),callback:function ($$v) {_vm.unlimited=$$v},expression:"unlimited"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Credit per Scan","label-for":"credit_per_scan"}},[(_vm.unlimited == true)?_c('b-form-input',{attrs:{"type":"number","id":"credit_per_scan","name":"credit_per_scan","onkeypress":"return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))","disabled":""},model:{value:(_vm.creditPerScan),callback:function ($$v) {_vm.creditPerScan=_vm._n($$v)},expression:"creditPerScan"}}):_c('b-form-input',{attrs:{"type":"number","id":"credit_per_scan","name":"credit_per_scan","onkeypress":"return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"},model:{value:(_vm.creditPerScan),callback:function ($$v) {_vm.creditPerScan=_vm._n($$v)},expression:"creditPerScan"}})],1),_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"primary","disabled":invalid,"size":"sm"}},[(_vm.isloading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Update")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }