<template>
  <b-card-code>
    <validation-observer ref="AgentEditForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="editAgent">
        <!-- Organization-->
        <b-form-group
          v-if="is_staff"
          label="Organization"
          label-for="Organization"
        >
          <validation-provider
            #default="{ errors }"
            name="organization"
            rules="required"
          >
            <!-- <v-select
              v-model="organization"
              label="text"
              placeholder="--Select Organization--"
              :options="organizations"
              :state="errors.length > 0 ? false : null"
              autocomplete
              :reduce="(organization) => organization.value"
              disabled
            /> -->
            <b-form-select
              v-model="organization"
              :options="organizations"
              :state="errors.length > 0 ? false : null"
              disabled
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Agent Type-->
        <b-form-group label="Agent Type" label-for="Agent Type">
          <validation-provider #default="{ errors }" name="Agent Type">
            <b-form-select
              class=""
              v-model="agent_type"
              :options="agent_types"
              disabled
            >
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Unlimited Credit" label-for="Unlimited">
          <validation-provider #default="{ errors }" name="Unlimited">
            <b-form-select
              class=""
              v-model="unlimited"
              :options="unlimted_options"
            >
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Credit per Scan" label-for="credit_per_scan">
          <b-form-input
            v-if="unlimited == true"
            type="number"
            id="credit_per_scan"
            v-model.number="creditPerScan"
            name="credit_per_scan"
            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
            disabled
          />
          <b-form-input
            v-else
            type="number"
            id="credit_per_scan"
            v-model.number="creditPerScan"
            name="credit_per_scan"
            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
          />
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid"
          class="my-1"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Update</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      organization: null,
      //   organization: !this.$store.state.app.user.is_staff
      //     ? this.$store.state.app.user.profile.organization
      //     : this.$store.state.app.org_id,
      organizations: [],
      users: [{ value: null, text: "--Select Owner--" }],
      isloading: false,
      agent_type: null,
      agent_types: [{ value: null, text: "--Select Agent Type--" }],
      creditPerScan: 0,
      unlimited: false,
      unlimted_options: [
        // { value: null, text: "--Select Unlimited--" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],
    };
  },
  created: function () {
    this.load();
    this.loadAgentTypes();
  },
  methods: {
    loadAgentTypes: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/type",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let type = {
            value: res.data[key].agent_type_id,
            text: res.data[key].agent_type_nicename,
          };
          // console.log(res.data[key].profile.organization)
          self.agent_types.push(type);
        });
      });
    },
    load: function () {
      if (this.is_staff) {
        // Load oragnization drop down
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/organization/get-all-org",
        };
        var self = this;
        this.$http(o_options).then((res) => {
          // console.log(res.data)
          res.data.map(function (value, key) {
            let org = {
              value: res.data[key].org_id,
              text: res.data[key].org_name,
            };
            // console.log(res.data[key].profile.organization)
            self.organizations.push(org);
          });
        });
      }
      var self = this;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "credit/org-agent-credit/" +
          this.$route.params.id,
      };
      this.$http(options).then((res) => {
        console.log(res.data, "agent configuration ffff");
        this.organization = res.data.organization;
        this.agent_type = res.data.agent_type;
        this.creditPerScan = res.data.credit_per_scan;
        this.unlimited = res.data.is_unlimited;
      });
    },
    editAgent: function () {
      this.$refs.AgentEditForm.validate().then((success) => {
        this.isloading = true;
        if (success) {
          //call to axios
          var self = this;
          let data = {
            credit_per_scan: this.creditPerScan,
            is_unlimited: this.unlimited,
          };
          const options = {
            method: "PATCH",
            headers: { "content-type": "application/json" },
            data: data,
            url:
              process.env.VUE_APP_BASEURL +
              "credit/org-agent-credit/" +
              this.$route.params.id +
              "/",
          };
          this.$http(options).then((res) => {
            console.log(res, "detailssss");
            if (res.data.id) {
              this.$router.go(-1);
              //   this.$router.push({ name: "Wallet" });
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Updated Successfully",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.isloading = false;
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>